import React, { FC, useEffect, useMemo, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { graphql } from 'gatsby';
import { GetCaseStudyLayoutImagesQuery } from '@generated/graphql';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { DefaultPageProps } from '@layouts/types';
import { BaseFooter } from '@components/base-footer/base-footer';
import { BaseNavigation } from '@components/base-navigation/base-navigation';
import { StaticAssetsProvider } from '@providers/static-assets';
import { HTMLHead } from '@components/html-head/html-head';
import { GatsbyImage } from 'gatsby-plugin-image';

export const query = graphql`
    query GetCaseStudyLayoutImages($mdxId: String!) {
        mdx(id: { eq: $mdxId }) {
            body
        }
        allFile {
            nodes {
                publicURL
                relativePath
                childImageSharp {
                    id
                    gatsbyImageData(placeholder: BLURRED, quality: 90)
                }
            }
        }
    }
`;

const CaseStudyLayout: FC<DefaultPageProps<GetCaseStudyLayoutImagesQuery>> = (props) => {
    const { pageContext, data, children } = props;

    const fixedHeroRef = useRef<HTMLDivElement>(null);
    const fixedHeroWrapperRef = useRef<HTMLDivElement>(null);
    const dimmedOverlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const HeroTl = gsap.timeline({
            scrollTrigger: {
                trigger: fixedHeroWrapperRef.current,
                start: 'bottom bottom',
                markers: false,
                scrub: true,
            },
        });

        HeroTl.to(fixedHeroRef.current, {
            yPercent: -25,
            ease: 'linear',
        });

        HeroTl.to(
            dimmedOverlayRef.current,
            {
                opacity: 0.8,
            },
            '<'
        );
    }, []);

    const headerImage = useMemo(() => {
        return data.allFile.nodes.find((file) => file.relativePath === pageContext.frontmatter.headerImage);
    }, [data.allFile.nodes, pageContext.frontmatter.headerImage]);

    return (
        <>
            <StaticAssetsProvider assets={data.allFile.nodes}>
                <HTMLHead title={pageContext?.frontmatter?.title as string | undefined} />
                <BaseNavigation />
                <div className="h-screen" ref={fixedHeroWrapperRef}>
                    <div className="w-full h-screen fixed" ref={fixedHeroRef}>
                        <div className="h-full container flex">
                            <div className="h-full w-full absolute left-0 top-0">
                                {headerImage?.childImageSharp?.gatsbyImageData && (
                                    // TODO: Put actual alt tag here
                                    <GatsbyImage
                                        alt="HeaderImage"
                                        className="h-full w-full"
                                        image={headerImage?.childImageSharp?.gatsbyImageData}
                                    />
                                )}
                            </div>

                            <div className="h-full w-full absolute top-0 left-0 bg-gradient-to-b from-alchem-dark to-transparent opacity-50" />

                            <div className="w-full flex flex-col justify-center relative">
                                <h1 className="text-8xl font-semibold mb-8 text-white">
                                    {pageContext?.frontmatter?.title}
                                </h1>
                            </div>

                            <div
                                className="bg-alchem-dark pointer-events-none absolute top-0 left-0 opacity-0 w-full h-full"
                                ref={dimmedOverlayRef}
                            />
                        </div>
                    </div>
                </div>
                <div className="relative bg-alchem-semidark">
                    <main>
                        {data?.mdx && <MDXRenderer>{data.mdx.body}</MDXRenderer>}
                        {children}
                    </main>
                    <BaseFooter />
                </div>
            </StaticAssetsProvider>
        </>
    );
};

export default CaseStudyLayout;
